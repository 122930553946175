import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Analytics } from '@genoa/analytics'
import { usePhoneVerification } from '@genoa/middle-end'
import { GenerateDevLoginResponse } from '@genoa/state-management/lib/modules/api/developer/types'
import { Box } from '@chakra-ui/react'
import { getAuth, signInWithCustomToken } from 'firebase/auth'

import { useAuthState } from '../../../contexts'
import { useReduxAction } from '../../../hooks'
import { RootState } from '../../../modules'
import { useGenerateDevLoginMutation } from '../../../modules/flexApi'
import { setPhoneNumberAction } from '../../../modules/onboarding'
import { useNavigation } from '../../../navigation'
import {
  Config,
  logger,
  setUserSignupStatus,
  useAmplitude,
  useAnalytics,
  useEnhancedTracking,
  useIterable,
} from '../../../providers'
import { createPhoneVerifiedEvent } from '../../../providers/iterable/user-events'
import { DevAuth } from './DevAuth'
import { SuccessfulPin } from './SuccessfulPin'

export const DevAuthContainer = () => {
  const { navigateToLogIn } = useNavigation()
  const setPhoneNumber = useReduxAction(setPhoneNumberAction)
  const phoneNumber = useSelector((state: RootState) => state.onboarding.phoneNumber)
  const [shouldResetAccount, setShouldResetAccount] = useState(false)
  const [generateDevLoginMutation, { isLoading }] = useGenerateDevLoginMutation()
  const { setProcessingLogin, tokenIsReady, isAnonymous, uid } = useAuthState()
  const [, phoneVerification] = usePhoneVerification()
  const { refetchExperimentsForUser, consentManagement } = useAmplitude()
  const iterable = useIterable()
  const { trackPhoneVerified } = useEnhancedTracking()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [smsNotificationsAccepted, setSmsNotificationsAccepted] = useState(false)
  const [customerId, setCustomerId] = useState('')
  const analytics = useAnalytics()

  useEffect(() => {
    if (Config.ENVIRONMENT === 'production') {
      navigateToLogIn()
    }
  }, [])

  const handleNext = async () => {
    const response: GenerateDevLoginResponse = await generateDevLoginMutation({
      phone_number: `+1${phoneNumber.extracted}`,
      reset_account: shouldResetAccount,
    }).unwrap()
    setProcessingLogin(true)
    const result = await signInWithCustomToken(getAuth(), response.data.token)
    setCustomerId(customerId)
  }

  const handleSMSOptInNext = () => {
    analytics.logEvent(Analytics.Events.SIGNUP_SMS_OPT_IN_CLICKED, {
      optedIn: smsNotificationsAccepted,
      devLogin: true,
    })
    if (smsNotificationsAccepted) {
      iterable.setSMSOptInStatus(smsNotificationsAccepted)
    }
  }

  const handleFetchExperiments = (customerId: string) => {
    try {
      return refetchExperimentsForUser(customerId)
    } catch (error: any) {
      logger.warn('DevAuthContainer', `Error loading experiments during sign in: ${error?.message}`)
    }
  }

  const handleRegisterSigninPinCodeNext = (customerId: string) => {
    try {
      return phoneVerification({ customer_public_id: customerId, phone_number: `1${phoneNumber.extracted}` })
    } catch (error: any) {
      logger.warn('DevAuthContainer', `Error during phone verification request: ${error?.message}`)
    }
  }

  useEffect(() => {
    const processTokenIsReady = async () => {
      await handleRegisterSigninPinCodeNext(uid!)
      await handleSuccessLogin()
    }

    if (tokenIsReady && !isAnonymous) {
      processTokenIsReady()
    }
  }, [tokenIsReady, isAnonymous, uid])

  const handleSuccessLogin = async () => {
    await handleFetchExperiments(customerId)

    iterable.addEvent(createPhoneVerifiedEvent())
    trackPhoneVerified(customerId)
    setUserSignupStatus()
    setShowSuccessModal(true)
  }

  const handleSuccessfulPinCodeNext = async () => {
    handleSMSOptInNext()
    setShowSuccessModal(false)
    setProcessingLogin(false)
  }

  const handleSMSOptIn = () => {
    setSmsNotificationsAccepted(!smsNotificationsAccepted)
  }
  const handleCheckbox = () => {
    setShouldResetAccount(!shouldResetAccount)
  }

  return (
    <Box>
      <DevAuth
        phone={phoneNumber.extracted}
        onPhoneChange={setPhoneNumber}
        onNextClick={handleNext}
        loading={isLoading}
        shouldResetAccount={shouldResetAccount}
        onCheck={handleCheckbox}
      />
      <SuccessfulPin
        visible={showSuccessModal}
        onClose={handleSuccessfulPinCodeNext}
        smsNotificationsAccepted={smsNotificationsAccepted}
        onSMSOptIn={handleSMSOptIn}
      />
    </Box>
  )
}
